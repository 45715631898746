import React, { useContext }  from "react"
import { Link } from "gatsby"

import { FinanceCalculatorContext } from "../modal/financing/storage/CalculatorContext"
import FinanceCalculator from "../../Modules/financeCalculator"
import useIncentives from "./hooks/VDPIncentives"

import { configurableString } from '../../Modules/configurableVehicleInfo'
import InnerHTML from 'dangerously-set-html-content'

import style from "./vdp.module.scss"
import { captureEvent } from "event-service/modules/capture"
export default function VDPFinancing(props) {
  const { Pricing, ListOfAppliedOffers } = props.vehicle
  const {IsNew} = props.vehicle.VehicleInfo

  const { months, downpayment, rates } = props.financing
  const { buttons, fallbackText, phoneNumber } = props

  const topRate = rates[0]
  const price = Number(Pricing.ExtraPrice1 || Pricing.List)
  const [renderIncentives, discountSum] = useIncentives(ListOfAppliedOffers,Pricing)

  const calc = new FinanceCalculator(price)
  const monthly = calc.calculateMonthlyPayment(
    price, discountSum, calc.rebate, months, topRate, downpayment
  )

  let clickToCallData
  if (typeof document !== 'undefined') {
    clickToCallData = {
      ...props.vehicle.VehicleInfo,
      price: props.vehicle.Pricing.ExtraPrice1,
    }
  }

  const [, setFinanceModalContext] = useContext(FinanceCalculatorContext)
  const contextualizeItem = () => {
    setFinanceModalContext({ finalPrice: price - discountSum, vehicleInfo: props.vehicle.VehicleInfo })
  }

  const setStyle = (button) => {
    if (button.hasOwnProperty("overrideTheme") && button.overrideTheme == true) {
      const styles = [
        button.hasOwnProperty("btncolor") && ["backgroundColor", button.btncolor],
        button.hasOwnProperty("btntxtcolor") && ["color", button.btntxtcolor]
      ].filter(el => el != false)
      return Object.fromEntries(styles)
    }
  }

  const renderHTMLBtn = (html) => (
    <div className="vdp-html-btns" data-vehicle-info={JSON.stringify(clickToCallData)}>
      <InnerHTML html={configurableString(html, props.vehicle.VehicleInfo, phoneNumber)} />
    </div>
  )
  function DetailPricing(Pricing){
    return Pricing.ExtraPrice1 != Pricing.List && Pricing.List > 0 && Pricing.ExtraPrice1 > 0 && Pricing.List > Pricing.ExtraPrice1  && IsNew
  }

  function NewWithDiscount(){
    return  price != 0  && discountSum > 0 && IsNew
  }
  function needHR(Pricing){
    return ((Pricing.ExtraPrice1 != Pricing.List && Pricing.List > 0 && Pricing.ExtraPrice1 > 0 && Pricing.List > Pricing.ExtraPrice1) && (discountSum == 0)) && IsNew
  }
  function netPrice(Pricing){
    return  ((Pricing.ExtraPrice1 != Pricing.List && Pricing.List > 0) || (discountSum > 0)) && Pricing.ExtraPrice1 > 0 && Pricing.List > Pricing.ExtraPrice1  && IsNew
  }
  function totalSavings(Pricing){
    return (discountSum > 0 || (Pricing.List - Pricing.ExtraPrice1) > 0) && Pricing.ExtraPrice1 > 0 && Pricing.List > Pricing.ExtraPrice1   && IsNew
  }

  return (
      <>
        <div className={style["normal-vdpf"]}>
          <section className={style["details"]}>
            <div className={style["details__inner"]}>
              <ul className={style["offers"]}>
                {
                  (DetailPricing(Pricing)) ? <li className={style["offer"]}>
                        <div className={style["offer-cont"]} style={{marginBottom: "7px", opacity: "0.5"}}>
                          <p className={style["offer-name"]}><button className={style["offer-btn"]}>DETAILED PRICING</button></p>
                          <p className={style["offer-value"]}>
                            <span className={style["offer-price-name"]}>{price != 0 && IsNew ? "MSRP" : "Retail Price"}</span>
                            {Pricing.List > 0 ? `$${FinanceCalculator.formatLocale(Pricing.List)}` : fallbackText}
                          </p>
                        </div>
                        <div className={style["offer-cont"]}>
                          <p className={style["offer-name"]}><button className={style["offer-btn"]}>Dealer Discount</button></p>
                          <p className={style["offer-value"]}>
                            {Pricing.ExtraPrice1 > 0 ? `-$${FinanceCalculator.formatLocale(Pricing.List - Pricing.ExtraPrice1)}` : fallbackText}
                          </p>
                        </div>
                      </li> :
                      (NewWithDiscount())
                          ?
                          <li className={style["offer"]}>
                            <div className={style["offer-cont"]} style={{marginBottom: "7px", opacity: "0.5"}}>
                              <p className={style["offer-name"]}><button className={style["offer-btn"]}>DETAILED PRICING</button></p>
                              <p className={style["offer-value"]}>
                                <span className={style["offer-price-name"]}>MSRP</span>
                                {Pricing.List > 0 ? `$${FinanceCalculator.formatLocale(Pricing.List)}` : fallbackText}
                              </p>
                            </div>
                          </li>:
                      <li className={style["offer"]}>
                        <div className={style["offer-cont"]}>
                          <p className={style["offer-name"]}><button className={style["offer-btn"]}>{price != 0 && IsNew && Pricing.List > Pricing.ExtraPrice1 ? "MSRP" : "Dealer Price"}</button></p>
                          <p className={style["offer-value"]}>
                            {price > 0 ? `$${FinanceCalculator.formatLocale(price)}` : fallbackText}
                          </p>
                        </div>
                      </li>
                }
                { ListOfAppliedOffers.length > 0 && renderIncentives() }
                { needHR(Pricing) &&
                   <hr style={{ width: "100%" }} />
                }
                
                  { netPrice(Pricing) &&
                    <div className={style["offer-cont"]}>
                      <b style={{ fontSize: "1.25em" }}>Net price</b>
                      <p style={{ fontSize: "1.25em" }}>
                        {`$${FinanceCalculator.formatLocale(price - discountSum)}`}
                      </p>
                    </div>
                  }


                {
                  (totalSavings(Pricing) && (
                      <>
                        <div className={style["offer-cont-savings"]}>
                          <b>Total savings</b>
                          <p>{`$${FinanceCalculator.formatLocale(discountSum + (Pricing.List - Pricing.ExtraPrice1))}`}</p>
                        </div>
                        <hr style={{ width: "100%" }} />
                      </>
                  ))
                }
                {
                  price > 0 && (
                      <li className={style["offer"]}>
                        <div className={style["offer-cont"]}>
                          <p className={style["offer-name"]}>
                            <button className={style["offer-btn"]}></button>
                          </p>
                            <Link className={style["offer-value"]} to={"#financecal"}>
                            <button className={style["offer-btn offer-btn_underline"] + " " + style["offer-finance"]} onClick={contextualizeItem}>
                              {`As Low As $${FinanceCalculator.formatLocale(monthly)}/Mo`}
                            </button>
                            </Link>
                        </div>
                      </li>
                  )
                }
              </ul>

            <div className={style["controls"]}>
              {buttons.map(button => (

                (button.html != undefined && button.html != '' && button.html != '<p></p>')

                  ? renderHTMLBtn(button.html)
                  :
                  (button.buttonLink.includes('tel:')
                    ?
                    <a className={style["control-a-wrap"]} href={button.buttonLink} >
                      <button className={style["control"]} style={setStyle(button)}
                       onClick={event => {
                          contextualizeItem()
                          captureEvent({
                            event: 'asc_cta_interaction',
                            element_text: `${event.currentTarget.innerText}`,
                            element_color: `${window.getComputedStyle(event.currentTarget).color}`,
                            element_type: 'button',
                            event_action: 'click',
                            event_action_result: 'open form',
                            link_url: `${button.buttonLink}`,
                            item_id: props.vehicle.VehicleInfo.VIN,
                            item_number: `${props.vehicle.VehicleInfo.Year} ${props.vehicle.VehicleInfo.Make} ${props.vehicle.VehicleInfo.Model} ${props.vehicle.VehicleInfo.Trim}`,
                            item_price: `${props.vehicle.Pricing.ExtraPrice1}`,
                            item_condition: `${props.vehicle.VehicleInfo.IsNew ? 'New' : 'Used'}`,
                            item_year: `${props.vehicle.VehicleInfo.Year}`,
                            item_make: `${props.vehicle.VehicleInfo.Make}`,
                            item_model: `${props.vehicle.VehicleInfo.Model}`,
                            item_variant: `${props.vehicle.VehicleInfo.Trim}`,
                            item_color: `${props.vehicle.VehicleInfo.ExteriorColor}`,
                            item_type: '',
                            item_category: '',
                            item_fuel_type: '',
                            item_inventory_date: ''
                          }, {
                            handlers: ['ASC']
                          }).then(res => console.log(res)).catch(err => console.log(err))
                        }}>
                        {
                          (button.buttonImage && button.buttonImage.length > 0) ? (
                            <img src={button.buttonImage} style={{ height: "100%" }} />
                          ) : button.buttonText
                        }
                      </button>
                    </a>
                    :
                    <Link className={style["control-a-wrap"]} to={button.buttonLink}>
                      <button className={style["control"]} style={setStyle(button)}
                        onClick={event => {
                          contextualizeItem()
                          captureEvent({
                            event: 'asc_cta_interaction',
                            element_text: `${event.currentTarget.innerText}`,
                            element_color: `${window.getComputedStyle(event.currentTarget).color}`,
                            element_type: 'button',
                            event_action: 'click',
                            event_action_result: 'open form',
                            link_url: `${button.buttonLink}`,
                            item_id: props.vehicle.VehicleInfo.VIN,
                            item_number: `${props.vehicle.VehicleInfo.Year} ${props.vehicle.VehicleInfo.Make} ${props.vehicle.VehicleInfo.Model} ${props.vehicle.VehicleInfo.Trim}`,
                            item_price: `${props.vehicle.Pricing.ExtraPrice1}`,
                            item_condition: `${props.vehicle.VehicleInfo.IsNew ? 'New' : 'Used'}`,
                            item_year: `${props.vehicle.VehicleInfo.Year}`,
                            item_make: `${props.vehicle.VehicleInfo.Make}`,
                            item_model: `${props.vehicle.VehicleInfo.Model}`,
                            item_variant: `${props.vehicle.VehicleInfo.Trim}`,
                            item_color: `${props.vehicle.VehicleInfo.ExteriorColor}`,
                            item_type: '',
                            item_category: '',
                            item_fuel_type: '',
                            item_inventory_date: ''
                          }, {
                            handlers: ['ASC']
                          }).then(res => console.log(res)).catch(err => console.log(err))
                        }}>
                        {
                          (button.buttonImage && button.buttonImage.length > 0) ? (
                            <img src={button.buttonImage} style={{ height: "100%" }} />
                          ) : button.buttonText
                        }
                      </button>
                    </Link>
                  )
              ))}
            </div>
          </div>
        </section>
      </div>
    </>
  )
}

VDPFinancing.defaultProps = {
  financing: {
    months: 0, downpayment: 0
  }
}
