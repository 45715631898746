import React, { useEffect, useState } from "react"

import style from "./vdp.module.scss"

import iconArrow from "../img/icon-arrow.svg"

const fields = [
  { label: "Stock #: ", value: "StockNumber" },
  { label: "VIN #: ", value: "VIN" },
  { label: "Year: ", value: "Year" },
  { label: "Make: ", value: "Make" },
  { label: "Model: ", value: "Model" },
  { label: "Trim: ", value: "Trim" },
  { label: "Drive Train: ", value: "Drivetrain" },
  { label: "Body: ", value: "BodyType" },
  { label: "Doors: ", value: "Doors" },
  { label: "Mileage: ", value: "Mileage" },
  { label: "Engine Cylinders: ", value: "Cylinders" },
  { label: "Transmission: ", value: "Transmission" },
  { label: "Interior Color: ", value: "InteriorColor" },
  { label: "Exterior Color: ", value: "ExteriorColor" }
]

export default function VDPDescription({ vehicle }) {

  const { Comments } = vehicle.VehicleInfo
  const [currentTab, setTab] = useState((Comments.length > 0 ? 0 : 1))
  
  const toggleTab = (idx) => {
    if (currentTab == idx) {
      setTab(-1)
    } else {
      setTab(idx)
    }
  }

  const tabsContent = [
    () => (
      <li className={style["tabs-content__item"]}>
        <div className={style["features"]}>
          <p className={style["features__description"]}>
            {Comments}
          </p>
        </div>
      </li>
    ),
    () => (
      <li className={style["tabs-content__item"]}>
        <div className={style["option-list"]}>
          {
            Object.keys(fields).map(key => (
              <div className={style["option-list__item"]} >
                <span className={style["option-list__option"]}>
                  {fields[key].label}
                </span>
                <span className={style["option-list__value"]}>
                  {
                    vehicle.VehicleInfo.hasOwnProperty(fields[key].value) &&
                    vehicle.VehicleInfo[fields[key].value]
                  }
                </span>
              </div>
            ))}
        </div>
      </li>
    )
  ]

  const tabs = ["Description", "Options"]

  return (
    <section className={style["tabs-wrap"]}>

      <ul className={style["tabs"]}>
        {tabs.map((tab, idx) => (
          ((Comments.length > 0 && tab === "Description") || tab === "Options") &&
          <>
            <li className={style["tabs__item"]}>
              <button
                className={`${style["tabs__btn"]} ${style[currentTab == idx && "tabs__btn_active"]}`}
                onClick={() => toggleTab(idx)}>
                <span className={style["tabs__btn-text"]}>{ tab }</span>
                <img className={`${style["tabs__btn-img"]} ${style[currentTab == idx && "caret-active"]}`} 
                  src={iconArrow} alt="" />
              </button>
            </li>
            <div className={style["tabs-resp"]}>
              {currentTab == idx && tabsContent[currentTab]()}
            </div>
          </>
        ))}
      </ul>

      <ul className={style["tabs-content"]}>
        {currentTab > -1 && tabsContent[currentTab]()}
      </ul>
    </section>
  )
}